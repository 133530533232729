const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SOCIAL_LOGIN: 'SOCIAL_LOGIN',
  SOCIAL_LOGIN_SUCCESS: 'SOCIAL_LOGIN_SUCCESS',
  SOCIAL_LOGIN_FAILURE: 'SOCIAL_LOGIN_FAILURE',

  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

  SOCIAL_SIGN_UP: 'SOCIAL_SIGN_UP',
  SOCIAL_SIGN_UP_SUCCESS: 'SOCIAL_SIGN_UP_SUCCESS',
  SOCIAL_SIGN_UP_FAILURE: 'SOCIAL_SIGN_UP_FAILURE',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  FETCH_UNREAD_MESSAGES_COUNT: 'FETCH_UNREAD_MESSAGES_COUNT',
  FETCH_UNREAD_MESSAGES_COUNT_SUCCESS: 'FETCH_UNREAD_MESSAGES_COUNT_SUCCESS',
  FETCH_UNREAD_MESSAGES_COUNT_FAILURE: 'FETCH_UNREAD_MESSAGES_COUNT_FAILURE',

  FETCH_UNREAD_NOTIFICATIONS_COUNT: 'FETCH_UNREAD_NOTIFICATIONS_COUNT',
  FETCH_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: 'FETCH_UNREAD_NOTIFICATIONS_COUNT_SUCCESS',
  FETCH_UNREAD_NOTIFICATIONS_COUNT_FAILURE: 'FETCH_UNREAD_NOTIFICATIONS_COUNT_FAILURE',

  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',

  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  UPDATE_LANGUAGE_SUCCESS: 'UPDATE_LANGUAGE_SUCCESS',
  UPDATE_LANGUAGE_FAILURE: 'UPDATE_LANGUAGE_FAILURE',

  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_SUCCESS: 'FETCH_CURRENT_USER_SUCCESS',
  FETCH_CURRENT_USER_FAILURE: 'FETCH_CURRENT_USER_FAILURE',

  FETCH_CURRENT_COMMUNITY: 'FETCH_CURRENT_COMMUNITY',
  FETCH_CURRENT_COMMUNITY_SUCCESS: 'FETCH_CURRENT_COMMUNITY_SUCCESS',
  FETCH_CURRENT_COMMUNITY_FAILURE: 'FETCH_CURRENT_COMMUNITY_FAILURE',

  CLEAR_CURRENT_COMMUNITY: 'CLEAR_CURRENT_COMMUNITY',

  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAILURE: 'FETCH_PERMISSIONS_FAILURE',

  SET_SERVER_SESSION: 'SET_SERVER_SESSION',
  UNSET_SERVER_SESSION: 'UNSET_SERVER_SESSION',

  RESEND_VERIFICATION_LINK: 'RESEND_VERIFICATION_LINK',
  RESEND_VERIFICATION_LINK_SUCCESS: 'RESEND_VERIFICATION_LINK_SUCCESS',
  RESEND_VERIFICATION_LINK_FAILURE: 'RESEND_VERIFICATION_LINK_FAILURE',

  HIDE_FLOATING_SIGNUP_BOX: 'HIDE_FLOATING_SIGNUP_BOX',

  SET_COMMUNITY_GUIDELINES: 'SET_COMMUNITY_GUIDELINES',

  FETCH_CURRENT_COMMUNITY_ROLES: 'FETCH_CURRENT_COMMUNITY_ROLES',
  FETCH_CURRENT_COMMUNITY_ROLES_SUCCESS: 'FETCH_CURRENT_COMMUNITY_ROLES_SUCCESS',
  FETCH_CURRENT_COMMUNITY_ROLES_FAILURE: 'FETCH_CURRENT_COMMUNITY_ROLES_FAILURE',

  UPDATE_MEMBER_ROLES: 'UPDATE_MEMBER_ROLES',
  UPDATE_MEMBER_ROLES_SUCCESS: 'UPDATE_MEMBER_ROLES_SUCCESS',
  UPDATE_MEMBER_ROLES_FAILURE: 'UPDATE_MEMBER_ROLES_FAILURE',

  FETCH_FEATURED_POSTS: 'FETCH_FEATURED_POSTS',
  FETCH_FEATURED_POSTS_SUCCESS: 'FETCH_FEATURED_POSTS_SUCCESS',
  FETCH_FEATURED_POSTS_FAILURE: 'FETCH_FEATURED_POSTS_FAILURE',

  DISMISS_FEATURED_POST: 'DISMISS_FEATURED_POST',

  GET_FOLLOWED_USERS: 'GET_FOLLOWED_USERS',

  UPDATE_PREFERENCE: 'UPDATE_PREFERENCE',
  UPDATE_PREFERENCE_SUCCESS: 'UPDATE_PREFERENCE_SUCCESS',
  UPDATE_PREFERENCE_FAILURE: 'UPDATE_PREFERENCE_FAILURE',

  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'UPDATE_EMAIL_FAILURE',

  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_USERNAME_SUCCESS: 'UPDATE_USERNAME_SUCCESS',
  UPDATE_USERNAME_FAILURE: 'UPDATE_USERNAME_FAILURE',

  SET_IS_ANDROID_USER: 'SET_IS_ANDROID_USER',
};

export default actionTypes;
