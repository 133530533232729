import styled, { css } from 'styled-components';
export default function SearchBarSuggestion({
  text,
  value
}: {
  text: string;
  value: string;
}) {
  const highlight = value;
  // queries with special characters (e.g. 'Pregnancy (difficulties)') were breaking the RegExp so they are now escaped
  const escapedHighlight = highlight.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
  return <>
      {parts.filter(Boolean).map(part => <Suggestion $isHighlighted={part.toLowerCase() === highlight.toLowerCase()} key={part}>
          {part}
        </Suggestion>)}
    </>;
}
const Suggestion = styled.span<{
  $isHighlighted?: boolean;
}>`
  ${({
  $isHighlighted
}) => $isHighlighted && css`
    font-weight: bold;
  `};
`;