import { ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { ButtonBase, PrimaryButtonStyles, SecondaryButtonStyles, LinkButtonStyles, NoneButtonStyles, DangerButtonStyles, TagStyles, DangerSecondaryButtonStyles } from 'styles-js/buttons';
import Icon, { IconType } from 'src/components/icon';
export type ButtonType = 'primary' | 'secondary' | 'link' | 'none' | 'danger' | 'dangerSecondary' | 'tag';
type Props = {
  ariaControls?: string;
  ariaLabel?: string;
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  id?: string;
  isSubmit?: boolean;
  role?: string;
  size?: 'sm' | 'lg';
  tabIndex?: number;
  text?: string | ReactNode;
  type?: ButtonType;
  onClick?: (e: SyntheticEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};
export default function Button({
  ariaControls,
  ariaLabel,
  children,
  className = '',
  dataTestId,
  disabled = false,
  fullWidth = false,
  icon,
  iconPosition = 'left',
  id,
  isSubmit = false,
  role,
  size,
  tabIndex,
  text = '',
  type = 'primary',
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}: Props) {
  return <StyledButton $cssType={type} $fullWidth={fullWidth} $size={size} aria-controls={ariaControls} aria-label={ariaLabel} className={className} data-testid={dataTestId} disabled={disabled} id={id} role={role} tabIndex={tabIndex} type={isSubmit ? 'submit' : 'button'} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-sentry-element="StyledButton" data-sentry-component="Button" data-sentry-source-file="button.tsx">
      {icon && iconPosition === 'left' && <><Icon icon={icon} />{' '}</>}
      {text || children}
      {icon && iconPosition === 'right' && <>{' '}<Icon icon={icon} /></>}
    </StyledButton>;
}
type StyledComponentProps = {
  $cssType?: ButtonType;
  $fullWidth?: boolean;
  $size?: 'sm' | 'lg';
};
const StyledButton = styled.button<StyledComponentProps>`
  ${ButtonBase};

  ${props => {
  switch (props.$cssType) {
    case 'primary':
      return css`${PrimaryButtonStyles}`;
    case 'secondary':
      return css`${SecondaryButtonStyles}`;
    case 'link':
      return css`${LinkButtonStyles}`;
    case 'none':
      return css`${NoneButtonStyles}`;
    case 'danger':
      return css`${DangerButtonStyles};`;
    case 'dangerSecondary':
      return css`${DangerSecondaryButtonStyles};`;
    case 'tag':
      return css`${TagStyles};`;
  }
}};

  ${props => {
  switch (props.$size) {
    case 'sm':
      return css`
          padding: 5px 10px;
        `;
    case 'lg':
      return css`
          padding: 15px 20px;
        `;
  }
}};

  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;