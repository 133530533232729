/* eslint-disable max-lines */
// @ts-strict-ignore
/* eslint-disable max-lines */
import actionTypes from 'src/modules/shared/context/actionTypes';
import communityActionTypes from 'src/modules/community/shared/actionTypes';
import wsActionTypes from 'src/modules/websockets/actionTypes';
import { generateAvatarLink } from 'src/utils/linkUtils';
import { userHasRole } from 'src/utils/context';
import { Context, Role } from 'src/constants/types';
import { Lang } from 'src/constants/language';
import { addIsHu, filteredDismissed } from 'src/utils/featuredPosts';

export const initialState: Context = {
  communityErrorStatusCode: null,
  currentCommunity: null,
  currentUser: null,
  featuredPosts: {
    posts: [],
    isLoading: false,
  },
  isAndroidUser: false,
  isFloatingSignupBoxVisible: true,
  lang: Lang.en,
  notifications: {
    isLoading: false,
    items: [],
    unreadNotificationsCount: 0,
  },
  sessionId: null,
  showVerificationBar: false,
  unreadMessagesCount: 0,
};

export default function Reducer(state = initialState, action): Context {
  switch (action.type) {
    case actionTypes.SET_SERVER_SESSION:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    case actionTypes.UNSET_SERVER_SESSION:
      return {
        ...state,
        sessionId: null,
      };
    case actionTypes.SET_IS_ANDROID_USER:
      return {
        ...state,
        isAndroidUser: action.isAndroidUser,
      };
    case actionTypes.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.user,
          avatarImageUrl: generateAvatarLink(action.user),
          isAccountPreferenceUpdating: false,
        },
        lang: action.user.locale.substring(0, 2),
        showVerificationBar: !!action.user.unverifiedEmail,
      };
    case actionTypes.GET_FOLLOWED_USERS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          followedUserIds: action.followedUserIds,
        },
      };
    case actionTypes.FETCH_CURRENT_COMMUNITY_SUCCESS: {
      const community = action.community;
      // don't overwrite guidelines if community doesn't change
      if (state.currentCommunity && state.currentCommunity.communityId === community.communityId) {
        community.guidelines = state.currentCommunity.guidelines;
      }
      return {
        ...state,
        currentCommunity: community,
        ...(state.currentUser && { currentUser: {
          ...state.currentUser,
          isCommunityMemberOrHUStaff: action.isMember,
          permissions: action.permissions,
          roles: action.roles,
        } }),
      };
    }
    case actionTypes.FETCH_CURRENT_COMMUNITY_FAILURE:
      return {
        ...state,
        currentCommunity: null,
        communityErrorStatusCode: action.statusCode,
      };
    case actionTypes.CLEAR_CURRENT_COMMUNITY:
      return {
        ...state,
        currentCommunity: null,
      };
    case actionTypes.FETCH_PERMISSIONS_SUCCESS: {
      // this action is dispatched on all community pages and only for logged in users.
      // It's also dispatched after signup and on follow/unfollow community actions.
      const isHUStaff = userHasRole(state.currentUser, Role.HealthUnlocked);
      const isMember = state.currentUser?.followedCommunities?.some(({ communityId }) => communityId === state.currentCommunity?.communityId);
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isCommunityMemberOrHUStaff: isMember || isHUStaff,
          permissions: action.permissions,
          roles: action.roles,
        },
      };
    }
    case actionTypes.LOGOUT_SUCCESS:
      return initialState;
    case communityActionTypes.UNFOLLOW_COMMUNITY_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          followedCommunities: state.currentUser.followedCommunities.filter(({ slug }) => slug !== action.communitySlug),
        },
      };
    case communityActionTypes.FETCH_COMMUNITY_TOPICS_SUCCESS:
      return {
        ...state,
        currentCommunity: {
          ...state.currentCommunity,
          topics: action.topics,
        },
      };
    case actionTypes.FETCH_UNREAD_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        unreadMessagesCount: action.count,
      };
    case actionTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unreadNotificationsCount: action.count,
        },
      };
    case actionTypes.FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
      };
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          items: action.notifications,
          isLoading: false,
        },
      };
    case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
        },
      };
    case wsActionTypes.UPDATE_MESSAGES_COUNT: {
      let unreadMessagesCount = state.unreadMessagesCount;
      switch (action.data.action) {
        case 'inc':
          unreadMessagesCount += action.data.count;
          break;
        case 'dec':
          unreadMessagesCount -= action.data.count;
          break;
        case 'set':
          unreadMessagesCount = action.data.count;
          break;
      }
      return {
        ...state,
        unreadMessagesCount: Math.max(unreadMessagesCount, 0),
      };
    }
    case wsActionTypes.UPDATE_NOTIFICATIONS_COUNT: {
      let unreadNotificationsCount = state.notifications.unreadNotificationsCount;
      switch (action.data.action) {
        case 'inc':
          unreadNotificationsCount += action.data.count;
          break;
        case 'dec':
          unreadNotificationsCount -= action.data.count;
          break;
        case 'set':
          unreadNotificationsCount = action.data.count;
          break;
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unreadNotificationsCount: Math.max(unreadNotificationsCount, 0),
        },
      };
    }
    case actionTypes.HIDE_FLOATING_SIGNUP_BOX:
      return {
        ...state,
        isFloatingSignupBoxVisible: false,
      };
    case actionTypes.SET_COMMUNITY_GUIDELINES:
      return {
        ...state,
        currentCommunity: {
          ...state.currentCommunity,
          extendedGuidelinesUrl: action.extendedGuidelinesUrl,
          guidelines: action.guidelines ? action.guidelines.sort((a, b) => a.number - b.number) : null,
        },
      };
    case actionTypes.FETCH_CURRENT_COMMUNITY_ROLES_SUCCESS:
      return {
        ...state,
        currentCommunity: {
          ...state.currentCommunity,
          roles: action.roles,
        },
      };
    case actionTypes.FETCH_FEATURED_POSTS:
      return {
        ...state,
        featuredPosts: {
          posts: [],
          isLoading: true,
        },
      };
    case actionTypes.FETCH_FEATURED_POSTS_SUCCESS: {
      return {
        ...state,
        featuredPosts: {
          posts: addIsHu(filteredDismissed(action.featuredPosts)),
          isLoading: false,
        },
      };
    }
    case actionTypes.DISMISS_FEATURED_POST:
      return {
        ...state,
        featuredPosts: {
          posts: filteredDismissed(state.featuredPosts.posts),
          isLoading: false,
        },
      };
    case actionTypes.UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        lang: action.lang,
      };
    case actionTypes.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...(state.currentUser.email && { email: action.newEmail }),
          ...(state.currentUser.unverifiedEmail  && { unverifiedEmail: action.newEmail }),
        },
      };
    case actionTypes.UPDATE_USERNAME_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          username: action.newUsername,
        },
      };
    case actionTypes.UPDATE_PREFERENCE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAccountPreferenceUpdating: true,
        },
      };
    case actionTypes.UPDATE_PREFERENCE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.preference,
          isAccountPreferenceUpdating: false,
        },
      };
    case actionTypes.UPDATE_PREFERENCE_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAccountPreferenceUpdating: false,
        },
      };
  }
  return state;
}
