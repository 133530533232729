/* eslint-disable max-lines */
// @ts-strict-ignore
import { Reply, SinglePost } from 'src/constants/types';
import actionTypes from 'src/modules/community/singlePost/actionTypes';
export const initialState: SinglePost = {
  composeReply: {
    imageHash: null,
    imageUploadError: null,
    isLoading: false,
    submittedReplyId: null
  },
  // temp only for the reply currently being posted/edited.
  freePostId: null,
  isLoading: false,
  isSecondPageBlockMsgBoxVisible: false,
  nextPosts: [],
  pinnedPosts: [],
  popularPosts: [],
  post: null,
  postError: null,
  relatedPosts: [],
  roles: null
};
const sortResponses = (responses: Reply[]) => {
  return [...responses].sort((a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  });
};
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_POST:
      return {
        ...state,
        isLoading: true,
        postError: null
      };
    case actionTypes.FETCH_POST_SUCCESS:
      return {
        ...state,
        post: action.post,
        isLoading: false,
        freePostId: state.freePostId || action.post.id
      };
    case actionTypes.FETCH_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        postError: {
          status: action.error?.response?.status || 500
        }
      };
    case actionTypes.FETCH_RELATED_POSTS_SUCCESS:
      return {
        ...state,
        relatedPosts: action.posts
      };
    case actionTypes.FETCH_RELATED_POSTS_FAILURE:
      return {
        ...state
      };
    case actionTypes.FETCH_POPULAR_POSTS_SUCCESS:
      return {
        ...state,
        popularPosts: action.posts
      };
    case actionTypes.FETCH_PINNED_POSTS_SUCCESS:
      return {
        ...state,
        pinnedPosts: action.posts
      };
    case actionTypes.FETCH_NEXT_POSTS_SUCCCESS:
      return {
        ...state,
        nextPosts: action.posts
      };
    case actionTypes.UPDATE_POST_LOCK_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          isLocked: action.isLocked
        }
      };
    case actionTypes.UPDATE_POST_PIN_SUCCESS:
      {
        const {
          username,
          avatar
        } = state.post.author;
        const updatedPinnedPosts = action.isPinned ? [{
          postId: action.postId,
          title: state.post.title,
          avatar,
          username
        }, ...state.pinnedPosts] : state.pinnedPosts.filter(({
          postId
        }) => action.postId != postId);
        return {
          ...state,
          post: {
            ...state.post,
            isPinned: action.isPinned
          },
          pinnedPosts: updatedPinnedPosts
        };
      }
    case actionTypes.DELETE_POST_REPLY_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          totalResponses: state.post ? state.post.totalResponses - 1 : 0,
          responses: state.post?.responses.filter(({
            id
          }) => id !== action.postId)
        }
      };
    case actionTypes.LIKE_POST_SUCCESS:
      if (state.post && action.postId === state.post?.id) {
        return {
          ...state,
          post: {
            ...state.post,
            hasRated: action.isLiked,
            numRatings: Number(state.post.numRatings) + (action.isLiked ? 1 : -1)
          }
        };
      }
      return {
        ...state,
        post: {
          ...state.post,
          responses: state.post?.responses.map(response => response.id === action.postId ? {
            ...response,
            hasRated: action.isLiked,
            numRatings: Number(response.numRatings) + (action.isLiked ? 1 : -1)
          } : response)
        }
      };
    case actionTypes.FOLLOW_POST_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          isFollowing: true
        }
      };
    case actionTypes.UNFOLLOW_POST_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          isFollowing: false
        }
      };
    case actionTypes.REPLY_POST:
    case actionTypes.UPDATE_POST_REPLY:
      return {
        ...state,
        composeReply: {
          isLoading: true
        }
      };
    case actionTypes.REPLY_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        composeReply: {
          ...state.composeReply,
          imageHash: action.hash,
          imageUploadError: null
        }
      };
    case actionTypes.REPLY_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        composeReply: {
          imageUploadError: action.error
        }
      };
    case actionTypes.REPLY_POST_SUCCESS:
      {
        const responses = [...(state.post?.responses || []), action.post];
        return {
          ...state,
          composeReply: {
            isLoading: false,
            submittedReplyId: action.post.id
          },
          post: {
            ...state.post,
            responses: sortResponses(responses),
            totalResponses: responses.length,
            mentionedUsers: {
              ...state.post?.mentionedUsers,
              ...action.post?.mentionedUsers
            }
          }
        };
      }
    case actionTypes.UPDATE_POST_REPLY_SUCCESS:
      return {
        ...state,
        composeReply: {
          isLoading: false,
          submittedReplyId: action.post.id
        },
        post: {
          ...state.post,
          responses: state.post?.responses.map(resp => resp.id === action.post.id ? {
            ...action.post
          } : resp),
          mentionedUsers: {
            ...state.post.mentionedUsers,
            ...action.post.mentionedUsers
          }
        }
      };
    case actionTypes.REPLY_POST_FAILURE:
    case actionTypes.UPDATE_POST_REPLY_FAILURE:
      return {
        ...state,
        composeReply: {
          isLoading: false
        }
      };
    case actionTypes.SHOW_SECOND_PAGE_BLOCK_MESSAGE_BOX:
      return {
        ...state,
        isSecondPageBlockMsgBoxVisible: true
      };
  }
  return state;
}