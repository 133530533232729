const actionTypes = {
  FETCH_COMMUNITY_POSTS: 'FETCH_COMMUNITY_POSTS',
  FETCH_COMMUNITY_POSTS_SUCCESS: 'FETCH_COMMUNITY_POSTS_SUCCESS',
  FETCH_COMMUNITY_POSTS_FAILURE: 'FETCH_COMMUNITY_POSTS_FAILURE',

  FETCH_STICKY_PINNED_POST_SUCCESS: 'FETCH_STICKY_PINNED_POST_SUCCESS',

  FETCH_COMMUNITY_WIDGETS: 'FETCH_COMMUNITY_WIDGETS',
  FETCH_COMMUNITY_WIDGETS_SUCCESS: 'FETCH_COMMUNITY_WIDGETS_SUCCESS',
  FETCH_COMMUNITY_WIDGETS_FAILURE: 'FETCH_COMMUNITY_WIDGETS_FAILURE',
};

export default actionTypes;
