const actionTypes = {
  FOLLOW_COMMUNITY_SUCCESS: 'FOLLOW_COMMUNITY_SUCCESS',
  FOLLOW_COMMUNITY_FAILURE: 'FOLLOW_COMMUNITY_FAILURE',

  UNFOLLOW_COMMUNITY_SUCCESS: 'UNFOLLOW_COMMUNITY_SUCCESS',
  UNFOLLOW_COMMUNITY_FAILURE: 'UNFOLLOW_COMMUNITY_FAILURE',

  FETCH_COMMUNITY_TOPICS_SUCCESS: 'FETCH_COMMUNITY_TOPICS_SUCCESS',
  FETCH_COMMUNITY_TOPICS_FAILURE: 'FETCH_COMMUNITY_TOPICS_FAILURE',

  RESTRICT_USER: 'RESTRICT_USER',
  UNRESTRICT_USER: 'UNRESTRICT_USER',
};

export default actionTypes;
