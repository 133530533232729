import { Role } from 'src/constants/types';

export type Community = {
  bannerImageHash: string;
  communityId: number;
  communityType: string;
  description: string;
  extendedGuidelinesUrl: string;
  followerCount: number;
  gaTrackingCode: string;
  guidelines: Guideline[];
  imageUrl?: string;
  isArchived: boolean;
  isPrivate: boolean;
  language: string;
  logoImageHash: string;
  name: string;
  postCount: number;
  roles: {
    customRoles: RoleObject[];
    defaultRoles: RoleObject[];
  };
  slug: string;
  topics?: Topic[];
  totalMembers: number;
};

export type Topic = {
  id: number;
  name: string;
  postCount: number;
}

export type Guideline = {
  number: number;
  title: string;
  subtitle: string;
}

export type RoleObject = {
  roleId: number;
  roleName: Role | string;
  roleDescription?: string;
}

export type topTag = {
  concept: 'CONDITION' | 'TREATMENT' | 'INTEREST';
  entityId: number;
  name: string;
  rank: number;
  slug: string;
}

// Community id 820
export type Blog = {
  description: string;
  slug: string;
  communityId: number;
  name: string;
  isArchived: boolean;
  followerCount: number;
  postCount: number;
  gaTrackingCode?: string;
  language: string;
  bannerImageHash: string;
  logoImageHash: string;
  isPrivate: boolean;
  communityType: string;
}

export const HUBlogId = 820;
