import i18n from 'src/utils/translate';
import { ActionType } from 'src/components/alert/types';
import { axiosTimeout } from 'src/utils/api';

export const setAlert = (
  message: string | JSX.Element = i18n.t('There was a problem. Please refresh the page and try again.'),
  type: 'success' | 'danger' = 'danger',
  timeout?: number | 'none',
) => {
  // Assigning default value when timeout is a number doesn't work without parentheses around the right-hand side operand
  const defaultTimeout = timeout ?? (type === 'danger' ? 'none' : 3000);
  const newMessage = typeof message === 'string' && message.includes(`timeout of ${axiosTimeout}ms exceeded`)
    ? i18n.t('It looks like our servers are very busy at the moment. Please try again later.')
    : message;
  return { type: ActionType.ALERT_SET, alert: { message: newMessage, type, timeout: defaultTimeout } };
};

export const unsetAlert = () => {
  return { type: ActionType.ALERT_DISMISS };
};
