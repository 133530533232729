import { RenderInputComponentProps } from 'react-autosuggest';
import styled, { css } from 'styled-components';
import { useEffect, useId } from 'react';
import i18n from 'src/utils/translate';
import { ButtonBase } from 'styles-js/buttons';
import Icon from 'src/components/icon';
import { text } from 'styles-js/mixins/typography';
type Props = {
  renderInputComponentProps: RenderInputComponentProps & {
    key?: string;
  };
  isFocus?: boolean;
  value: string;
  className?: string;
};
export default function SearchBarInput({
  renderInputComponentProps,
  isFocus,
  value
}: Props) {
  const id = useId();
  useEffect(() => {
    // useRef on the input was interfering with renderInputComponentProps.ref
    const input = (document.querySelector(`[id="js-search-bar-input-${id}"`) as HTMLInputElement);
    if (isFocus) {
      input?.focus();
    } else {
      input?.blur();
    }
  }, [isFocus, id]);
  delete renderInputComponentProps.key;
  return <Label $isFocus={isFocus} data-sentry-element="Label" data-sentry-component="SearchBarInput" data-sentry-source-file="searchBarInput.tsx">
      <input aria-label={i18n.t('Start a search')} data-testid="search-bar-input" id={`js-search-bar-input-${id}`} maxLength={100} {...renderInputComponentProps} // eslint-disable-line react/jsx-props-no-spreading
    />
      <SearchBarIconButton $isFocus={isFocus} aria-label={i18n.t('Search')} data-testid="search-bar-icon-button" disabled={!value && !isFocus} type="submit" data-sentry-element="SearchBarIconButton" data-sentry-source-file="searchBarInput.tsx">
        <Icon icon="search" data-sentry-element="Icon" data-sentry-source-file="searchBarInput.tsx" />
      </SearchBarIconButton>
    </Label>;
}
const Label = styled.label<{
  $isFocus?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;

  input {
    width: 100%;
    ${text}
    appearance: none;
    box-shadow: none;
    padding: 6px 12px;
    background-color: ${({
  theme
}) => theme.colorBlueBackground};
    flex-grow: 1;
    height: 100%;
    border-radius: 5px 0 0 5px;
    border: 1px solid ${({
  theme
}) => theme.colorBlueBackground};
    transition: border-color 0.7s ease;

    ::placeholder {
      color: ${({
  theme
}) => theme.colorBlack};
    }

    ${({
  $isFocus
}) => $isFocus && css`
      border: 1px solid ${({
  theme
}) => theme.colorBlue};
    `};
  }
`;
const SearchBarIconButton = styled.button<{
  $isFocus?: boolean;
}>`
  ${ButtonBase};
  width: 60px;
  font-size: 25px;
  border-radius: 0 5px 5px 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: ${({
  theme
}) => theme.colorBlueBackground};
  color: ${({
  theme
}) => theme.colorTextBlack};
  &:hover {
    background-color: ${({
  theme
}) => theme.colorBlue};
    color: ${({
  theme
}) => theme.colorWhite};
  }
  &:disabled {
    background-color: ${({
  theme
}) => theme.colorBlueBackground};
    border-color: ${({
  theme
}) => theme.colorBlueBackground};
    color: ${({
  theme
}) => theme.colorGreyDark};
    cursor: initial;
    opacity: 1;
  }
  &:disabled:hover {
    background-color: ${({
  theme
}) => theme.colorGreyLight};
  }
  ${({
  $isFocus
}) => $isFocus && css`
    background-color: ${({
  theme
}) => theme.colorBlue};
    border-color: ${({
  theme
}) => theme.colorBlue};
    color: ${({
  theme
}) => theme.colorWhite};
  `};
`;