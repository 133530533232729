/* eslint-disable max-lines */
// @ts-strict-ignore
/*
  We had performance issues with the beacon blocking the JS main thread even when it
  was loaded after page load. The workaround is to load it on click.

  After page load, we use the MutationObserver API to watch for changes in the
  head tag in the DOM.
  When the user clicks on a link that opens the beacon, we load the beacon in the DOM.
  When the beacon is loaded, it adds 3 script tags in the head tag.
  The main script file for the beacon is 375KB... Once this file is added to the DOM,
  we show the beacon or the article requested.

  If the MutationObserver API is not supported by the browser, Help Scout Beacon
  is loaded in the DOM after page load.
*/

import articles, { articleType } from 'src/constants/helpScoutArticles';

export default function helpScoutBeacon() {
  const helpScoutConfig = {
    beacons: {
      USER_SUPPORT: '03f0d25e-b1f3-4e3f-ba79-25de7a90f081',
    },
    articles,
    routes: {
      'contact-form': '/ask/',
    },
  };

  const beaconConfig = {
    display: { zIndex: 2000 },
    showPrefilledCustomFields: true,
  };

  let beaconToShow = helpScoutConfig.beacons.USER_SUPPORT,
    mutationObserver,
    articleToShow,
    contactFormFields,
    routeToNavigateTo,
    options,
    isInitialised = false;

  // Load the beacon in DOM
  function loadHelpScoutBeacon() {
    // @ts-expect-error ts-migrate(1345) FIXME: An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
    // eslint-disable-next-line
    !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  }

  function initialiseBeacon() {
    if (!isInitialised) {
      isInitialised = true;
      window.Beacon('init', beaconToShow);
      window.Beacon('config', beaconConfig);
      window.Beacon('on', 'close', () => {
        window.Beacon('reset');
        window.Beacon('destroy');
        isInitialised = false;
      });
    }
  }

  function showBeacon() {
    initialiseBeacon();
    window.Beacon('open');
    window.Beacon('navigate', '/answers/');
  }

  function showArticle() {
    initialiseBeacon();
    window.Beacon('navigate', '/answers/');
    window.Beacon('article', articleToShow, options);

    if (options && options.type === 'modal') {
      onModalClose(() => {
        window.Beacon('destroy');
        isInitialised = false;
      });
    }
  }

  function showScreen() {
    initialiseBeacon();
    window.Beacon('open');
    window.Beacon('navigate', routeToNavigateTo);
  }

  function showReportForm() {
    initialiseBeacon();
    window.Beacon('open');
    window.Beacon('navigate', '/ask/message/');
    window.Beacon('prefill', {
      name: contactFormFields.name,
      subject: contactFormFields.subject,
      text: contactFormFields.text,
      fields: contactFormFields.fields,
    });
  }

  const showLoadingSpinner = () => {
    const img = document.createElement('img');
    img.id = 'beacon-loader';
    img.style.position = 'fixed';
    img.style.left = '20px';
    img.style.bottom = '20px';
    img.src = 'https://images.hu-production.be/static/img/green-spinner.svg';
    document.body.appendChild(img);

    const interval = window.setInterval(() => {
      if (window.document.getElementById('beacon-container')) {
        document.body.removeChild(img);
        window.clearInterval(interval);
      }
    }, 500);
  };

  const showIfBeaconLoaded = (showItem) => {
    if (window.Beacon) {
      showItem();
    } else {
      showLoadingSpinner();
      loadHelpScoutBeacon();
    }
  };
  // Called on click events in the app
  const triggerHelpScoutArticle = (article, opts) => {
    articleToShow = helpScoutConfig.articles[article] || article; // if slug doesn't exist assume `article` is an id, BB-2957
    options = opts;

    showIfBeaconLoaded(showArticle);
  };
  // Called on click events in the app
  const triggerHelpScoutScreen = (screen) => {
    routeToNavigateTo = helpScoutConfig.routes[screen];

    showIfBeaconLoaded(showScreen);
  };
  // Called on click events in the app
  const triggerHelpScoutBeacon = (beacon) => {
    // Overwrites default beacon if needed
    if (beacon) {
      beaconToShow = helpScoutConfig.beacons[beacon];
    }
    showIfBeaconLoaded(showBeacon);
  };

  const triggerHelpScoutReportForm = ({
    name,
    subject,
    text,
  }) => {
    contactFormFields = {
      name,
      subject,
      text,
      fields: [ // custom fields
        {
          id: 32037, // 'Reason for contacting' custom dropdown field id
          value: 165656, // 'Report profile' option id
        },
      ],
    };
    showIfBeaconLoaded(showReportForm);
  };

  // Make those 4 functions available to the app
  window.HelpScoutBeacon = {
    triggerHelpScoutArticle,
    triggerHelpScoutBeacon,
    triggerHelpScoutReportForm,
    triggerHelpScoutScreen,
  };

  // Callback function called every time a mutation has occurred
  function observerCallback(mutations) {
    mutations.forEach((mutation) => {
      // If Help Scout main script file added to head tag
      if (
        mutation.addedNodes
        && mutation.addedNodes.length > 0
        && mutation.addedNodes[0].src // addedNodes always only have one item
        && mutation.addedNodes[0].src.includes('https://beacon-v2.helpscout.net/static/js/main')
      ) {
        if (articleToShow) {
          showArticle();
        } else if (contactFormFields) {
          showReportForm();
        } else if (routeToNavigateTo) {
          showScreen();
        } else if (beaconToShow) {
          showBeacon();
        }
        // Stops the MutationObserver from listening for changes
        mutationObserver.disconnect();
      }
    });
  }

  function addMutationObserver() {
    if (window.MutationObserver) {
      mutationObserver = new MutationObserver(observerCallback);
      // Watch for DOM changes in head tag only
      mutationObserver.observe(document.head, { childList: true });
    } else {
      // If MutationObserver isn't supported in browser, we load the beacon after page load
      loadHelpScoutBeacon();
    }
  }
  // After page is fully loaded, we start watching for DOM changes
  window.addEventListener('load', addMutationObserver, false);
}

function onModalClose(callback) {
  let hasOpened = false;
  const interval = window.setInterval(() => {
    const modal = window.document.getElementById('BeaconInlineArticlesFrame');
    if (modal) {
      hasOpened = true;
    } else if (hasOpened) {
      callback();
      window.clearInterval(interval);
    }
    if (!hasOpened) window.clearInterval(interval);
  }, 100);
}

export const helpScoutArticle = (article: articleType, options?: { type: 'modal' }): void =>
  window.HelpScoutBeacon.triggerHelpScoutArticle(article, options);

export const helpScoutContactForm = (): void => window.HelpScoutBeacon.triggerHelpScoutScreen('contact-form');

export const helpScoutReportForm = ({ name, subject, text }: { name: string, subject: string, text: string }): void =>
  window.HelpScoutBeacon.triggerHelpScoutReportForm({ name, subject, text });
