import Router from 'next/router';
import { Post } from 'src/constants/types';

export const performSearch = (
  query: string,
  communityId = 'all',
  communitySlug?: string | null,
  searchType = 'posts',
): void => {
  if (query) {
    switch (searchType) {
      case 'communities':
        redirectToSearchCommunityPage({ query });
        break;
      case 'members':
      case 'posts':
      default:
        redirectToSearchPage({ query, communityId, communitySlug, searchType });
        break;
    }
  }
};

export const redirectToSearchPage = ({
  communityId = 'all',
  communitySlug,
  query,
  searchType = 'posts',
}: {
  communityId?: string | number;
  communitySlug?: string | null;
  query: string;
  searchType?: string;
}): void => {
  if (communitySlug) {
    Router.push(
      { pathname: '/community/communitySearch', query: { communitySlug, searchType, query } },
      { pathname: `/${communitySlug}/search/${searchType}`, query: { query } },
      { scroll: false },
    );
  } else {
    Router.push(
      { pathname: '/search', query: { query, community: communityId } },
      { pathname: `/search/${searchType}`, query: { query, community: communityId } },
      { scroll: false },
    );
  }
};

const redirectToSearchCommunityPage = ({ query, page = 1 }: { query: string, page?: number }): void => {
  Router.push(
    `/search?searchType=communities&query=${query}&page=${page}`,
    `/search/communities?query=${query}&page=${page}`,
    { scroll: false },
  );
};

export const getExpectedPostUrl = (
  post: Post,
  communitySlug: string,
  currentUrl: string,
  host?: string,
): string => {
  const { search = '' } = new URL(currentUrl, `http://${host}/`);
  const privatePrefix = post.isPrivate ? '/private' : '';
  return `/${communitySlug}/posts${privatePrefix}/${post.id}/${post.urlEncodedTitle}${search}`;
};

// Removing recipient from URL once it's processed. We don't want to leak 'recipient'
// in referrer for social media actions - e.g. if users click on log in with fb/google
// Shallow routing has to be done after first render, page isn't replaced, only route state is changed
export const removeRecipientFromUrl = (queryParams: Record<string, string>): void => {
  const newSearchParams = new URLSearchParams(location.search.substring(1));
  newSearchParams.delete('recipient');
  const paramsString = newSearchParams.size > 0 ? `?${newSearchParams.toString()}` : '';

  // For custom router
  if (queryParams.url) {
    Router.replace(
      { pathname: Router.pathname, query: queryParams },
      `${location.pathname}${paramsString}`,
      { shallow: true },
    );
  } else { // For Pages Router
    Router.replace(`${location.pathname}${paramsString}`, undefined, { shallow: true });
  }
};
