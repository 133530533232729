import { captureMessage } from '@sentry/nextjs';
import { useEffect, useMemo } from 'react';
import { articleType } from 'src/constants/helpScoutArticles';
import { Featured } from 'src/constants/types';
import { prepLink } from 'src/utils/linkUtils';
import { getLocalStorageItem, setLocalStorageItem, Key } from 'src/utils/localStorage';
import { fetchFeaturedPosts, hideFloatingSignupBox } from 'src/modules/shared/context/actions';
import { useIsLoggedIn } from 'src/utils/hooks/useIsLoggedIn';
import { useDispatch, useSelector } from 'src/store/store';

export const featuredPostJsFunctionRegex = /^js:(.+?)(\?(.*))?$/;

const getDismissedFeaturedPostIds = () => {
  return JSON.parse(getLocalStorageItem(Key.featuredPostsDismissed) || '[]');
};

export const addToDismissList = (featuredPostId: number) => {
  const dismissed = JSON.parse(getLocalStorageItem(Key.featuredPostsDismissed) || '[]');
  dismissed.push(featuredPostId);
  setLocalStorageItem(Key.featuredPostsDismissed, JSON.stringify(dismissed));
};

export const isUniqueImpression = (featuredPostId: number) => {
  const uniqueImpressions = JSON.parse(getLocalStorageItem(Key.featuredPostUniqueImpressions) || '[]');
  return uniqueImpressions.indexOf(featuredPostId) === -1;
};

export const addToUniqueImpressionsList = (featuredPostId: number) => {
  const uniqueImpressions = JSON.parse(getLocalStorageItem(Key.featuredPostUniqueImpressions) || '[]');
  uniqueImpressions.push(featuredPostId);
  setLocalStorageItem(Key.featuredPostUniqueImpressions, JSON.stringify(uniqueImpressions));
};

export const getModalParams = (url: string): Record<string, string> | null => {
  const query = url.split('?')[1];
  if (!query) return null;
  const params = (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params: Record<string, string>, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  if (Object.keys(params).length > 0) {
    return params;
  } else {
    return null;
  }
};

export const getHelpScoutArticle = (url: string, featuredPostId: number): articleType | null => {
  const article = url.split('=')[1];
  if (article) {
    return article as articleType;
  } else {
    captureMessage(`Featured Post ${featuredPostId} URL error: ${url}`);
    return null;
  }
};

export const formatLink = (url?: string): string => {
  return getCtaType(url) === 'link' ? prepLink(url) : '';
};

const getFunctionName = (callToActionUrl: string) => {
  const matches = featuredPostJsFunctionRegex.exec(callToActionUrl);
  const functionName = Array.isArray(matches) && matches[1];
  return functionName;
};

const isModalCta = (url?: string): boolean => { // js:openModal?popup=${popupId}
  return Boolean(url?.startsWith('js:') && getFunctionName(url) === 'openModal');
};

const isHelpScoutCta = (url?: string): boolean => { // js:helpscout?article=${articleId || articleSlug}
  return Boolean(url?.startsWith('js:') && getFunctionName(url) === 'helpscout');
};

const isMailtoCta = (url?: string): boolean => { // mailto:${email}
  return Boolean(url?.startsWith('mailto:'));
};

const isOldModalCta = (url?: string): boolean => { // http://www.openmod.al
  return Boolean(url?.startsWith('http://www.openmod.al'));
};

type CtaType = 'modal' | 'helpscout' | 'mailto' | 'oldModal' | 'link';

export const getCtaType = (url?: string): CtaType => {
  if (isModalCta(url)) {
    return 'modal';
  } else if (isHelpScoutCta(url)) {
    return 'helpscout';
  } else if (isMailtoCta(url)) {
    return 'mailto';
  } else if (isOldModalCta(url)) {
    return 'oldModal';
  } else {
    return 'link';
  }
};

export function getPostsToDisplay(featuredPosts: Featured[]): [Featured | null, Featured | null] {
  let top = null;
  let bottom = null;

  if (featuredPosts?.length > 0) {
    const huPosts = featuredPosts.filter(({ isHu }) => isHu);
    const communityPosts = featuredPosts.filter(({ isHu }) => !isHu);

    if (huPosts.length > 0) { // Top = HU posts
      top = huPosts[0];
      if (communityPosts.length > 0) { // Bottom = community posts
        bottom = communityPosts[0];
      } else { // no community posts, use HU post
        bottom = huPosts[1];
      }
    } else if (communityPosts.length > 0) { // no HU posts, use community post
      top = communityPosts[0];
      bottom = communityPosts[1];
    }
  }

  return [top, bottom];
}

export function useFetchFeaturedPosts() {
  const dispatch = useDispatch();
  const language = useSelector(({ context }) => context.lang);
  const communityId = useSelector(({ context }) => context.currentCommunity?.communityId);
  const postId = useSelector(({ pages }) => pages.singlePost.post?.id);
  const userType = useIsLoggedIn() ? 'member' : 'visitor';
  const featuredPosts = useSelector(({ context }) => context.featuredPosts.posts);

  useEffect(() => {
    if (communityId) {
      dispatch(fetchFeaturedPosts({ communityId, language, userType }));
    }
  }, [communityId, dispatch, userType, language]);

  // only want to re-sort on a new SPP or community home page
  return useMemo(() => getPostsToDisplay(sortWeightedByScore(featuredPosts)), [postId, communityId, featuredPosts]); // eslint-disable-line

}

export function filteredDismissed(featuredPosts: Featured[]): Featured[] {
  return featuredPosts.filter(({ featuredPostId }) => !getDismissedFeaturedPostIds().includes(featuredPostId));
}

export function addIsHu(featuredPosts: Featured[]): Featured[] {
  return featuredPosts.map((post) => ({
    ...post,
    isHu: post.featuredByCommunityName === 'HealthUnlocked',
  }));
}

const priorityToScore = {
  '1': 1,
  '2': 2,
  '3': 4,
  '4': 6,
  '5': 8,
};

export function sortWeightedByScore(featuredPosts: Featured[]): Featured[] {
  if (featuredPosts.length <= 1) return featuredPosts;

  const total = featuredPosts.reduce((acc, post) => acc + priorityToScore[post.priority], 0);
  const postsWithWeightedScore = featuredPosts.map((post) => ({
    post,
    weightedScore: (priorityToScore[post.priority] / total) * Math.random(),
  }));

  return postsWithWeightedScore.sort((a, b) => b.weightedScore - a.weightedScore).map(({ post }) => post);
}

export const useHUStickyCampaign = () => {
  const dispatch = useDispatch();
  const featuredPosts = useSelector(({ context }) => context.featuredPosts.posts);
  const { currentUser, isFloatingSignupBoxVisible } = useSelector(({ context }) => context);

  const firstHuCampaign = featuredPosts?.find(({ isHu }) => isHu);

  const showCampaign = !currentUser && firstHuCampaign;

  useEffect(() => {
    if (showCampaign && isFloatingSignupBoxVisible) {
      dispatch(hideFloatingSignupBox());
    }
  }, [showCampaign, isFloatingSignupBoxVisible, dispatch]);

  return showCampaign ? firstHuCampaign : null;
};
