/* eslint-disable react/jsx-key */
// keys are causing dots to be rendered when they shouldn't be.
// go to page 4, click 3, click 2, and dots will show between 1 and 2.
import Link from 'next/link';
import { useUpdateSearchParam } from 'src/modules/search/hooks';
type Props = {
  isScrollToTop?: boolean;
  lastPageOverride: number;
  pageSize: number;
  total: number;
  page: number;
};
export default function PageLinks({
  isScrollToTop = false,
  lastPageOverride,
  pageSize,
  total,
  page
}: Props) {
  const lastPage = Math.ceil(total / pageSize);
  const maxPageNum = lastPage < lastPageOverride ? lastPage : lastPageOverride; // temporary fix for error on pages 500+
  const getLinkProps = useUpdateSearchParam('page');
  const range = pageRange(page, maxPageNum);
  return range.map(p => {
    if (p === page) {
      return <li className="active">{p}</li>;
    } else if (typeof p === 'number') {
      const {
        as,
        href
      } = getLinkProps(String(p));
      return <li><Link as={as} href={href} scroll={isScrollToTop}>{p}</Link></li>;
    } else {
      return <li className="dots">{p}</li>;
    }
  });
}
export function pageRange(current: number, max: number) {
  if (max === 1) return [];
  const array = [1, current - 1, current, current + 1, max];
  const range = array.filter((x, i) => {
    return array.indexOf(x) === i && x > 0 && x <= max;
  });
  if (range[1] - range[0] > 1) (range as (string | number)[]).splice(1, 0, '...');
  if (range[range.length - 1] - range[range.length - 2] > 1) (range as (string | number)[]).splice(range.length - 1, 0, '...');
  return range;
}