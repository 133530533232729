import React from 'react';
import styled from 'styled-components';
type ContainerProps = {
  heightSize?: number;
  borderRadius?: number;
};
type Props = ContainerProps & {
  colour?: string;
  progress: number;
};
export default function ProgressBar({
  borderRadius,
  colour = 'blue',
  heightSize = 10,
  progress = 100
}: Props) {
  return <Container borderRadius={borderRadius} heightSize={heightSize} data-sentry-element="Container" data-sentry-component="ProgressBar" data-sentry-source-file="progressBar.tsx">
      <Bar borderRadius={borderRadius} colour={colour} heightSize={heightSize} progress={progress} data-sentry-element="Bar" data-sentry-source-file="progressBar.tsx" />
    </Container>;
}
const Container = styled.div<ContainerProps>`
  width: 100%; // needed if component has position:absolute as inline style
  height: ${({
  heightSize
}) => heightSize + 'px'};
  background-color: ${({
  theme
}) => theme.colorBlueBackground};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius + 'px'}`};
  z-index: 1;
`;
const Bar = styled.div<Props>`
  width: ${({
  progress
}) => progress + '%'};
  height: ${({
  heightSize
}) => heightSize + 'px'};
  background-color: ${({
  theme,
  colour
}) => colour === 'green' ? theme.colorGreen : theme.colorBlue};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius + 'px'}`};
`;