import { useRouter } from 'next/router';
import styled from 'styled-components';
import MultiplierLinks from 'src/components/pagination/mutiplierLinks';
import NextPageLink from 'src/components/pagination/nextPageLink';
import PageLinks from 'src/components/pagination/pageLinks';
import { textLinkPrimary } from 'styles-js/mixins/typography';
const maxNbPages = 10;
const jumpMultiplier = 10;
const leapMultiplier = 100;
type Props = {
  isScrollToTop?: boolean;
  isShowBotLinks?: boolean;
  lastPageOverride?: number;
  pageSize?: number;
  total: number;
};
export default function Pagination({
  isScrollToTop = false,
  isShowBotLinks = false,
  lastPageOverride = 500,
  pageSize = 30,
  total = 0
}: Props) {
  const router = useRouter();
  const page = Number(router.query?.page) || 1;
  if (total === 0) return null;
  return <>
      <Ul data-sentry-element="Ul" data-sentry-source-file="pagination.tsx">
        <PageLinks isScrollToTop={isScrollToTop} lastPageOverride={lastPageOverride} page={page} pageSize={pageSize} total={total} data-sentry-element="PageLinks" data-sentry-source-file="pagination.tsx" />
        <NextPageLink isScrollToTop={isScrollToTop} lastPageOverride={lastPageOverride} page={page} pageSize={pageSize} total={total} data-sentry-element="NextPageLink" data-sentry-source-file="pagination.tsx" />
      </Ul>
      <MultiplierLinks isScrollToTop={isScrollToTop} isShowBotLinks={isShowBotLinks} lastPageOverride={lastPageOverride} limit={0} maxNbPages={maxNbPages} multiplier={jumpMultiplier} page={page} pageSize={pageSize} total={total} data-sentry-element="MultiplierLinks" data-sentry-source-file="pagination.tsx" />
      <MultiplierLinks isScrollToTop={isScrollToTop} isShowBotLinks={isShowBotLinks} lastPageOverride={lastPageOverride} limit={2} maxNbPages={maxNbPages} multiplier={leapMultiplier} page={page} pageSize={pageSize} total={total} data-sentry-element="MultiplierLinks" data-sentry-source-file="pagination.tsx" />
    </>;
}
export const Ul = styled.ul`
  margin: 20px 3px;
  padding: 0;

  li {
    padding: 7px 12px;
    display: inline-block;
    
    &:not(:last-child) {
      border-right: ${({
  theme
}) => theme.greyLine};
    }

    a {
      ${textLinkPrimary}
      text-decoration: none;
      color: ${({
  theme
}) => theme.colorBlue};
    }

    &.dots {
      color: ${({
  theme
}) => theme.colorGrey};
    }

    &.active {
      color: ${({
  theme
}) => theme.colorBlack};
    }
  }
`;