import Image from 'next/image';
import styled from 'styled-components';
import { generateAvatarLinkFromHash } from 'src/utils/linkUtils';
type Props = {
  alt?: string;
  name?: string;
  size?: Size;
  className?: string;
};
type WithUrl = Props & {
  hash?: never;
  url: string;
};
type WithHash = Props & {
  hash: string;
  url?: never;
};
export enum Size {
  XSmall = 28,
  Small = 40,
  Medium = 60,
  Large = 130,
}
// if the avatar is for a community, pass in a url - this generates an avatar url from hash !
export default function Avatar({
  alt,
  name = '',
  size = Size.XSmall,
  url,
  hash,
  className
}: WithUrl | WithHash) {
  const src = url || generateAvatarLinkFromHash(hash);
  return <StyledImage alt={alt || `${name} profile image`} className={className} height={size} src={src} unoptimized={true} width={size} data-sentry-element="StyledImage" data-sentry-component="Avatar" data-sentry-source-file="avatar.tsx" />;
}
const StyledImage = styled(Image)`
  display: inline-block;
  border-radius: 50%;
`;