export enum ModalType {
  CreatePollConfirmation = 'CreatePollConfirmation',
  ChooseTopic = 'ChooseTopic',
  CommunityGuidelines = 'CommunityGuidelines',
  CommunityInvites = 'CommunityInvites',
  CommunityRemoveMember = 'CommunityRemoveMember',
  CommunityTopConditions = 'CommunityTopConditions',
  Confirm = 'Confirm',
  ContactAdmins = 'ContactAdmins',
  ContactDetails = 'ContactDetails',
  Default = 'Default',
  DeletePoll = 'DeletePoll',
  DeletePost = 'DeletePost',
  EditBadges = 'EditBadges',
  EmailConfirmation = 'EmailConfirmation',
  EmailSubscribeAllPosts = 'EmailSubscribeAllPosts',
  EmailUnsubscribeAllEmails = 'EmailUnsubscribeAllEmails',
  ExistingLoginSocial = 'ExistingLoginSocial',
  Feedback = 'Feedback',
  GuidelinesAdd = 'GuidelinesAdd',
  GuidelinesDelete = 'GuidelinesDelete',
  GuidelinesInfo = 'GuidelinesInfo',
  Iframe = 'Iframe',
  ImageDescription = 'ImageDescription',
  Login = 'Login',
  MarketingOptIn = 'MarketingOptIn',
  MessageArchive = 'MessageArchive',
  MessageEditSubject = 'MessageEditSubject',
  MessageLeave = 'MessageLeave',
  MessageMute = 'MessageMute',
  MessageNew = 'MessageNew',
  MessageParticipants = 'MessageParticipants',
  NeedsAssessment = 'NeedsAssessment',
  NHSTagContent = 'NHSTagContent',
  Onboarding = 'Onboarding',
  PrivacyReminder = 'PrivacyReminder',
  PrivacyTips = 'PrivacyTips',
  PrivacyTipsImages = 'PrivacyTipsImages',
  ProfileSurvey = 'ProfileSurvey',
  ProgramCompleted = 'ProgramCompleted',
  ProgramEnroll = 'ProgramEnroll',
  Report = 'Report',
  SavePost = 'SavePost',
  SearchFilterOptions = 'SearchFilterOptions',
  SearchSortBy = 'SearchSortBy',
  SelectCommunityToPost = 'SelectCommunityToPost',
  SentimentFeedback = 'SentimentFeedback',
  Signup = 'Signup',
  StickyPinnedPostModal = 'StickyPinnedPostModal',
  StopEditingPoll = 'StopEditingPoll',
  SupportDetails = 'SupportDetails',
  TagFilterOptions = 'TagFilterOptions',
  TakeProfileSurvey = 'TakeProfileSurvey',
  TakeProfileSurveyOnSignup = 'TakeProfileSurveyOnSignup',
  Welcome = 'Welcome',
}
