import { FormEvent, useId, useState, useCallback, useEffect } from 'react';
import Autosuggest, { OnSuggestionSelected, SuggestionsFetchRequested } from 'react-autosuggest';
import { debounce } from 'lodash';
import styled, { css } from 'styled-components';
import SearchBarSuggestion from 'src/modules/search/shared/components/searchBarSuggestion';
import SearchBarInput from 'src/modules/search/shared/components/searchBarInput';
import { text } from 'styles-js/mixins/typography';
type Props = {
  className?: string;
  dataTestId?: string;
  defaultValue?: string;
  isAutoFocus?: boolean;
  isSmall?: boolean;
  placeholder: string;
  suggestions: string[];
  setSuggestions: (suggestions: string[]) => void;
  onSuggestionsFetchRequested: SuggestionsFetchRequested;
  onClick?: () => void;
  onSubmit: (suggestion: string) => void;
};
export function SearchBarForm({
  className,
  dataTestId,
  defaultValue = '',
  isAutoFocus = false,
  isSmall,
  placeholder,
  suggestions,
  setSuggestions,
  onClick,
  onSubmit,
  onSuggestionsFetchRequested
}: Props) {
  const id = useId();
  const [isFocus, setIsFocus] = useState(isAutoFocus);
  const {
    value,
    setValue
  } = useValue(defaultValue);
  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFocus(false);
    onSubmit(value);
    setValue('');
  };
  const onSuggestionSelected: OnSuggestionSelected<string> = (_, {
    suggestion
  }) => {
    setIsFocus(false);
    onSubmit(suggestion);
    setValue('');
  };
  const debouncedOnSuggestionsFetchRequested = useCallback(debounce(onSuggestionsFetchRequested, 300), [onSuggestionsFetchRequested]); // eslint-disable-line react-hooks/exhaustive-deps, max-len

  return <Form $isSmall={isSmall} className={className} data-testid={dataTestId} onSubmit={onFormSubmit} data-sentry-element="Form" data-sentry-component="SearchBarForm" data-sentry-source-file="searchBarForm.tsx">
      <Autosuggest getSuggestionValue={suggestion => suggestion} id={id} inputProps={{
      placeholder,
      value,
      onClick,
      onChange: (_, {
        newValue
      }) => setValue(newValue),
      onBlur: () => setIsFocus?.(false),
      onFocus: () => setIsFocus?.(true)
    }} renderInputComponent={props => <SearchBarInput isFocus={isFocus} renderInputComponentProps={props} value={value} />} renderSuggestion={text => <SearchBarSuggestion text={text} value={value} />} suggestions={suggestions} onSuggestionsClearRequested={() => setSuggestions?.([])} onSuggestionSelected={onSuggestionSelected} onSuggestionsFetchRequested={debouncedOnSuggestionsFetchRequested} data-sentry-element="Autosuggest" data-sentry-source-file="searchBarForm.tsx" />
    </Form>;
}
function useValue(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return {
    value,
    setValue
  };
}
const Form = styled.form<{
  $isSmall?: boolean;
}>`
  position: relative;
  height: 48px;
  width: 100%;
  max-width: 700px;

  .react-autosuggest__container {
    height: 100%;
  }
  .react-autosuggest__suggestions-container--open {
    border-radius: 4px;
    border: 1px solid ${({
  theme
}) => theme.colorGreyLight};
    box-shadow: ${({
  theme
}) => theme.shadowDefault};
    background-color: ${({
  theme
}) => theme.colorWhite};
    position: absolute;
    z-index: 200;
    top: 48px;
    width: 100%;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .react-autosuggest__suggestion {
    ${text}
    cursor: pointer;
    padding: 10px 15px;

  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({
  theme
}) => theme.colorBlueBackground};
  }

  ${({
  $isSmall
}) => $isSmall && css`
    height: 36px;
    .react-autosuggest__suggestions-container--open {
      top: 36px;
    }
    input {
      font-size: 16px;
    }
    button[type="submit"] {
      width: 42px;
      font-size: 18px;
    }
  `}
`;