// @ts-strict-ignore
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { createDoughnutLegends } from 'src/utils/chartJs';
import EmptyState from 'src/modules/community/communityMetrics/emptyState';
import DoughnutChartFallback from 'src/modules/community/communityMetrics/doughnutChartFallback';
import { SubTitle, ChartContainer, DoughnutContainer, DoughnutCanvasContainer, LegendContainer } from 'src/modules/community/communityMetrics/sharedStyles';
import { theme } from 'styles-js/theme';
export default function Age() {
  const {
    age: {
      colours,
      labels,
      total,
      values
    }
  } = useSelector(({
    pages
  }) => pages.communityMetrics.membership);
  const data = {
    labels,
    datasets: [{
      data: values,
      backgroundColor: colours,
      borderWidth: 1
    }]
  };
  const options = {
    layout: {
      padding: 0
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      },
      htmlLegend: {
        containerId: 'age-legend-container'
      },
      tooltip: {
        callbacks: {
          label: context => `${context.formattedValue}%`
        },
        displayColors: false
      }
    }
  };
  const doughnutLabel = {
    id: 'doughnutLabel',
    afterDatasetsDraw(chart) {
      const {
        ctx
      } = chart;
      const centerX = chart.getDatasetMeta(0).data[0]?.x;
      const centerY = chart.getDatasetMeta(0).data[0]?.y;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = theme.colorBlack;
      ctx.font = `18px Lato, "Helvetica Neue", Helvetica, Arial, sans-serif`;
      ctx.fillText(`${total}`, centerX, centerY - 12);
      ctx.font = `14px Lato, "Helvetica Neue", Helvetica, Arial, sans-serif`;
      ctx.fillStyle = theme.colorGreyDark;
      ctx.fillText('TOTAL', centerX, centerY + 12);
    }
  };
  return <ChartContainer $noBreakInsideOnPrint={true} data-sentry-element="ChartContainer" data-sentry-component="Age" data-sentry-source-file="age.tsx">
      <SubTitle data-testid="chart-caption-2" data-sentry-element="SubTitle" data-sentry-source-file="age.tsx">{i18n.t('Age groups')}</SubTitle>
      {values.length > 0 ? <DoughnutContainer>
          <LegendContainer id="age-legend-container" />
          <DoughnutCanvasContainer>
            <Doughnut aria-label={i18n.t('Age groups')} data={data} fallbackContent={<DoughnutChartFallback labels={labels} total={total} values={values} />} options={options} plugins={[doughnutLabel, htmlLegendPlugin]} />
          </DoughnutCanvasContainer>
        </DoughnutContainer> : <EmptyState />}
    </ChartContainer>;
}
const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, _, options) {
    createDoughnutLegends({
      chart,
      options
    });
  }
};
export const ageChartColours = [{
  group: '18-24',
  colour: theme.colorBlueLighter
}, {
  group: '25-44',
  colour: theme.colorYellow
}, {
  group: '45-59',
  colour: theme.colorGreen
}, {
  group: '60+',
  colour: theme.colorPurple
}];